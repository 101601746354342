<template>
  <div style="padding: 20px">
    <v-dialog v-model="etsy_data_dialog" scrollable max-width="800px">
      <v-card style="padding: 20px; height: 100%">
        <div v-if="fetching_etsy_data" style="padding: 60px 0px" class="text-center">
          <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
        </div>
        <div v-else>
          <div class="mt-5 mb-5 text-center" v-if="!etsy_data.success">
            <h2>Something went wrong!!</h2>
          </div>
          <div v-else>
            <json-viewer :value="etsy_data.response" copyable expanded></json-viewer>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-data-table :headers="headers" :items="products.data" class="elevation-1" hide-default-footer disable-pagination>
      <template v-slot:top>
        <v-row style="padding: 10px">
          <v-col md="2" cols="6"> <strong>Total: </strong> {{ products.total }} </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="searchTerm"
              label="Search Keyword"
              outlined
              dense
              placeholder="Search Keyword"
              @change="fetchEtsyProducts()"
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:body="{ items }">
        <tbody v-if="!is_loading">
          <tr v-if="!items.length">
            <td colspan="3" class="text-center" style="padding: 20px 0px">
              <img :src="require('@/assets/images/misc/no-data.png')" alt="" width="250px" />
              <p style="font-size: 25px">No Data Found!!</p>
            </td>
          </tr>
          <tr v-else v-for="item in items" :key="item.id">
            <td style="padding: 10px; width: 45%" :set="(etsy_images = JSON.parse(item.fetched_imagesdata))">
              <div style="display: flex">
                <img
                  :src="
                    etsy_images && etsy_images[0] && etsy_images[0].url_fullxfull
                      ? etsy_images[0].url_fullxfull
                      : require('@/assets/images/misc/no-image.png')
                  "
                  width="100px"
                  height="100px"
                  alt=""
                  class="mr-3"
                />
                <div>
                  <span class="primary--text" @click="etsyProductDetail(item.id)">{{ item.title }}</span>
                  <p class="mt-2"><strong>Listing ID:</strong> {{ item.listing_id }}</p>
                  <p class="mt-2"><strong>SKU: <span v-html="showSKUStatus(item)"></span></strong></p>
                </div>
              </div>
            </td>
            <td
              v-if="item.shopify_product"
              style="padding: 10px; width: 45%"
              :set="(shopify_attrs = JSON.parse(item.shopify_product.attributes))"
            >
              <div style="display: flex">
                <img
                  :src="
                    shopify_attrs.image_url ? shopify_attrs.image_url : require('@/assets/images/misc/no-image.png')
                  "
                  width="100px"
                  height="100px"
                  alt=""
                  class="mr-3"
                />
                <div>
                  <span class="primary--text" @click="shopifyProductDetail(item.shopify_product.id)">{{ item.shopify_product.title }}</span>
                  <p class="mt-2"><strong>Shopify ID:</strong> {{ item.shopify_product.shopify_id }}</p>
                </div>
              </div>
            </td>
            <td v-else>Something Went Wrong!!</td>
            <td>
              <v-btn color="success" @click="setProductOnSync(item)" class="tet-white mb-1 btn-xs" small
                >Sync Now</v-btn
              >
              <v-btn color="red" @click="forceSyncProduct(item)" dark class="text-white mb-1 btn-xs" small
                >Force Sync</v-btn
              >
              <v-btn color="primary" @click="getEtsyApiDetail(item.listing_id)" class="tet-white btn-xs" small
                >View Raw</v-btn
              >
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6" class="text-center" style="padding: 80px 0px">
              <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-pagination
      v-model="products.current_page"
      class="my-4"
      @input="handlePageChange"
      :length="products.last_page"
      :total-visible="7"
    ></v-pagination>
  </div>
</template>
<script>
import store from '@/store'
import JsonViewer from 'vue-json-viewer'
import router from '@/router'

export default {
  props: {
    search: String,
  },
  data() {
    return {
      shop: null,
      is_loading: false,
      configurations: {},
      products: {
        current_page: 1,
        data: [],
      },
      headers: [{ text: 'Etsy Product', value: 'title' }, { text: 'Shopify Product' }, { text: 'Actions' }],

      etsy_data_dialog: false,
      fetching_etsy_data: false,
      etsy_data: {},
      product_count: {},
      searchTerm: this.search,
    }
  },
  components: {
    JsonViewer,
  },
  mounted() {
    this.shop = store.getters.loggedInShop
    this.fetchEtsyProducts()
  },
  methods: {
    async fetchEtsyProducts() {
      try {
        this.is_loading = true
        this.$emit('updateSearch', this.searchTerm);
        let response = await axios.get(`/etsy/products`, {
          params: {
            shop_id: this.shop.id,
            type: 1,
            page: this.products.current_page,
            search: this.searchTerm,
          },
        })
        if(response.data)
        {
            this.products = response.data
            this.product_count = response.data.product_count
           this.$emit('count-updated', this.product_count);
        }
      } catch (e) {
      } finally {
        this.is_loading = false
      }
    },

    handlePageChange(value) {
      this.fetchEtsyProducts()
    },

    async getEtsyApiDetail(listing_id) {
      try {
        this.etsy_data_dialog = true
        this.fetching_etsy_data = true
        let response = await axios.get(`admin/shops/${this.shop.id}/etsy-listing/${listing_id}/get-raw-data`)
        // if (response.data.success) {
        this.etsy_data = response.data
        // }
      } catch (e) {
      } finally {
        this.fetching_etsy_data = false
      }
    },

    async etsyProductDetail(etsyProductId)
    {
      router.push(`/etsy-products/${etsyProductId}`);
    },

    async shopifyProductDetail(shopifyId)
    {
      router.push(`/shopify-products/${shopifyId}`);
    },

    async setProductOnSync(item, force_update = false) {
      let shopify_id = item.shopify_product.id

      let response = await axios.post(`/set-product-upload-queue`, {
        shop_id: this.shop.id,
        product_id: shopify_id,
        is_forced: force_update,
      })

      this.$swal.fire({
        icon: 'success',
        title: 'Done',
        text: 'Product Sync Started',
      })
    },

    forceSyncProduct(item) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          html: 'You want to force-upload the product? <br> <br> Doing this will ignore the price sync setting',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })
        .then(result => {
          if (result.isConfirmed) this.setProductOnSync(item, true)
        })
    },


    showSKUStatus(product)
    {
      let parent_data = product.upload_result;
      let prepared_data = product.data;

        let item = JSON.parse(parent_data);
        let prepared_item = JSON.parse(prepared_data ? prepared_data : "[]" );

        return `${item ? (item.has_variations ? "skus in variations" : item.skus[0]) : (prepared_item?.has_variations ? "sku in variations" : prepared_item?.skus)} <br/> ${item ? item.quantity : prepared_item?.quantity} in stock | ${item?.price.currency_code} 
                ${item ? (item.price.amount / item.price.divisor) : prepared_item?.price} <br/> Status: ${product.etsy_state}`
    },
  },
}
</script>