<template>
  <div>
    <h2 class="text-h5 mb-4">Etsy Products</h2>
    <!-- <v-card style="padding:20px"> -->
    <v-tabs v-model="tab">
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-0"> All &nbsp;<span class="primary--text" >{{product_count.all}}</span></v-tab>
      <v-tab href="#tab-1"> Linked Products  &nbsp;<span class="primary--text">{{product_count.linked_product}}</span></v-tab>
      <v-tab href="#tab-2"> SKU Linked  &nbsp;<span class="primary--text">{{product_count.linked_sku}}</span></v-tab>

      <v-tab href="#tab-3"> Not Linked  &nbsp;<span class="primary--text">{{product_count.not_linked}}</span></v-tab>

      <v-tab href="#tab-4"> Created on Shopify  &nbsp;<span class="primary--text">{{product_count.created_on_shopify}}</span></v-tab>
      <v-tab href="#tab-5"> Needs Attention  &nbsp;<span class="primary--text">{{product_count.need_attention}}</span></v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item :key="0" value="tab-0">
        <all-etsy-products :search="search" @updateSearch="updateSearch" @count-updated="updateCountValue"></all-etsy-products>
      </v-tab-item>

      <v-tab-item :key="1" value="tab-1">
        <linked-products :search="search" @updateSearch="updateSearch" @count-updated="updateCountValue"></linked-products>
      </v-tab-item>

      <v-tab-item :key="2" value="tab-2">
        <sku-linked-products :search="search" @updateSearch="updateSearch" @count-updated="updateCountValue"></sku-linked-products>
      </v-tab-item>

      <v-tab-item :key="3" value="tab-3">
        <not-linked-products :search="search" @updateSearch="updateSearch" @count-updated="updateCountValue"></not-linked-products>
      </v-tab-item>

      <v-tab-item :key="4" value="tab-4">
        <created-on-shopify :search="search" @updateSearch="updateSearch" @count-updated="updateCountValue"></created-on-shopify>
      </v-tab-item>
      <v-tab-item :key="5" value="tab-5">
        <needs-attention :search="search" @updateSearch="updateSearch" @count-updated="updateCountValue"></needs-attention>
      </v-tab-item>

      <!--         
        <v-tab-item>
          <v-card>
            <h1>HELLO</h1>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <h1>HELLO</h1>
          </v-card>
        </v-tab-item> -->
      <!-- <v-tab-item v-for="i in 3" :key="i" :value="'tab-' + i">
          <v-card flat>
            <v-card-text>{{ text }}</v-card-text>
          </v-card>
        </v-tab-item> -->
    </v-tabs-items>
    <!-- </v-card> -->
  </div>
</template>

<script>
import AllEtsyProducts from './AllEtsyProducts.vue'
import LinkedProducts from './LinkedProducts.vue'
import SkuLinkedProducts from './SkuLinkedProducts.vue'
import NotLinkedProducts from './NotLinkedProducts.vue'
import CreatedOnShopify from './CreatedOnShopify.vue'
import NeedsAttention from './NeedsAttention.vue'
export default {
  components: {
    AllEtsyProducts,
    SkuLinkedProducts,
    LinkedProducts,
    NotLinkedProducts,
    CreatedOnShopify,
    NeedsAttention,
  },
  data() {
    return {
      product_count: {
        all: 0,
        linked_product: 0,
        linked_sku: 0,
        created_on_shopify: 0,
        not_linked: 0,
        need_attention: 0,
      },
      tab: null,
      search: "",
    }
  },
   methods: {
    updateCountValue(new_count) {
      this.product_count = new_count;
    },
    updateSearch(newSearch) {
      this.search = newSearch;
    },
  },
}
</script>