<template>
  <div style="padding: 20px">
    <v-dialog v-model="openVariationsModal" scrollable max-width="800px">
      <v-card style="padding: 20px; height: 100%">
        <div>
              <table>
                <thead>
                  <tr>
                    <th>Shopify Product</th>
                    <th>SKU</th>
                    <th>Linking Type</th>
                    <th>Stock</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in linkedProducts(selectedShopifyProduct)" :key="index">
                    <td style="padding: 10px;">
                      <a @click="shopifyProductDetail(item.id)" target="_blank">
                        {{ item.title }}
                      </a>
                    </td>
                    <td style="padding: 10px;">{{ item.sku }}</td>
                    <td style="padding: 10px;">
                      <span v-if="isProductLinked(item)">Product Linked</span>
                      <span v-else>SKU Linked</span>
                    </td>
                    <td style="padding: 10px;">{{ formatStock(item.stock_quantity) }}</td>
                  </tr>
                </tbody>
              </table> 
           </div>
      </v-card>
    </v-dialog>

    <v-data-table :headers="headers" :items="products.data" class="elevation-1" hide-default-footer disable-pagination>
      <template v-slot:top>
        <v-row style="padding: 10px">
          <v-col md="2" cols="6"> <strong>Total: </strong> {{ products.total }} </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="searchTerm"
              label="Search Keyword"
              outlined
              dense
              placeholder="Search Keyword"
              @change="fetchEtsyProducts()"
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:body="{ items }">
        <tbody v-if="!is_loading">
          <tr v-if="!items.length">
            <td colspan="3" class="text-center" style="padding: 20px 0px">
              <img :src="require('@/assets/images/misc/no-data.png')" alt="" width="250px" />
              <p style="font-size: 25px">No Data Found!!</p>
            </td>
          </tr>
          <tr v-else v-for="item in items" :key="item.id">
            <td style="padding: 10px; width: 45%" :set="(etsy_images = JSON.parse(item.fetched_imagesdata))">
              <div style="display: flex">
                <img
                  :src="
                    etsy_images && etsy_images[0] && etsy_images[0].url_fullxfull
                      ? etsy_images[0].url_fullxfull
                      : require('@/assets/images/misc/no-image.png')
                  "
                  width="100px"
                  height="100px"
                  alt=""
                  class="mr-3"
                />
                <div>
                  <span class="primary--text" @click="etsyProductDetail(item.id)">{{ item.title }}</span>
                  <p class="mt-2"><strong>Listing ID:</strong> {{ item.listing_id }}</p>
                  <p class=""><strong>SKU: <span v-html="showSKUStatus(item)"></span></strong></p>
                </div>
              </div>
            </td>
                  
             <td
                v-if="item.shopify_product ||
                item.shared_sku_shopify_products.length > 0"
                style="padding: 10px; width: 25%"
                :set="(sku_shared_shopify_products = item.shared_sku_shopify_products)"
              >
              <div v-if="item.shopify_product &&
                  item.variations_count > 0 &&
                  JSON.parse(item.upload_result).has_variations">
                <div style="display: flex">
                  <p>stock in variations</p>
                 
                  <!-- <div>
                    <span class="primary--text" @click="shopifyProductDetail(item.shared_sku_shopify_products[0].id)">{{ item.shared_sku_shopify_products[0].title }}</span>
                    <p class="mt-2"><strong>Shopify ID:</strong> {{ item.shared_sku_shopify_products[0].shopify_id }}</p>
                  </div> -->
                </div>
              </div>
              <div
                 v-else-if="item.variations_count > 0 &&
                      item.shared_sku_shopify_products.length > 0 &&
                      JSON.parse(item.upload_result).has_variations">
                  stock in variations
              </div>
              <div v-else-if="item.shared_sku_shopify_products.length > 0">
                 <img
                    :src=" require('@/assets/images/logos/shopify.png')
                    "
                    width="35px"
                    height="45px"
                    alt=""
                    class="mr-3"
                    @click="openVariationsPopup(item)"
                  />
                      {{ uniqueStockQuantities(item.shared_sku_shopify_products) }}
              </div>
              <div v-else-if="product.shopify_product">
                 <img
                    :src=" require('@/assets/images/logos/shopify.png')
                    "
                    width="35px"
                    height="45px"
                    alt=""
                    class="mr-3"
                    @click="openVariationsPopup(item)"
                  />
                  <p v-if="product && product.shopify_product">Stock Quantity: {{ product.shopify_product.stock_quantity }}</p>
                  <p v-else>Stock Quantity not available</p>
              </div>
            </td>
            <td v-else>
              <p>Not Available</p>
            </td>
            <td style="padding: 10px; width: 25%">
                <v-chip small class="ma-2" :color="getChipColor(item)" text-color="white">
                  {{getLinkingStatus(item)}}
                </v-chip>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6" class="text-center" style="padding: 80px 0px">
              <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-pagination
      v-model="products.current_page"
      class="my-4"
      @input="handlePageChange"
      :length="products.last_page"
      :total-visible="7"
    ></v-pagination>
  </div>
</template>
<script>
import store from '@/store'
import JsonViewer from 'vue-json-viewer'
import router from '@/router'

export default {
  props: {
    search: String,
  },
  data() {
    return {
      shop: null,
      is_loading: false,
      openVariationsModal: false,
      selectedShopifyProduct: null,
      configurations: {},
      products: {
        current_page: 1,
        data: [],
      },
      headers: [{ text: 'Etsy Product', value: 'title' }, { text: 'Shopify Product' }, { text: 'Linking Status' }],

      etsy_data_dialog: false,
      fetching_etsy_data: false,
      etsy_data: {},
      product_count: {},
      searchTerm: this.search,
    }
  },
  components: {
    JsonViewer,
  },
  mounted() {
    this.shop = store.getters.loggedInShop
    this.fetchEtsyProducts()
  },
  methods: {
    async fetchEtsyProducts() {
      try {
        this.is_loading = true
        this.$emit('updateSearch', this.searchTerm);
        let response = await axios.get(`/etsy/products`, {
          params: {
            shop_id: this.shop.id,
            type: 0,
            page: this.products.current_page,
            search: this.searchTerm,
          },
        })
        if(response.data)
        {
            this.products = response.data
            this.product_count = response.data.product_count
          this.$emit('count-updated', this.product_count);
        }
      } catch (e) {
      } finally {
        this.is_loading = false
      }
    },
    

    handlePageChange(value) {
      this.fetchEtsyProducts()
    },

    async getEtsyApiDetail(listing_id) {
      try {
        this.etsy_data_dialog = true
        this.fetching_etsy_data = true
        let response = await axios.get(`admin/shops/${this.shop.id}/etsy-listing/${listing_id}/get-raw-data`)
        // if (response.data.success) {
        this.etsy_data = response.data
        // }
      } catch (e) {
      } finally {
        this.fetching_etsy_data = false
      }
    },

    async etsyProductDetail(etsyProductId)
    {
      router.push(`/etsy-products/${etsyProductId}`);
    },

    async shopifyProductDetail(shopifyId)
    {
      router.push(`/shopify-products/${shopifyId}`);
    },

    async setProductOnSync(item, force_update = false) {
      let shopify_id = item.shopify_product.id

      let response = await axios.post(`/set-product-upload-queue`, {
        shop_id: this.shop.id,
        product_id: shopify_id,
        is_forced: force_update,
      })

      this.$swal.fire({
        icon: 'success',
        title: 'Done',
        text: 'Product Sync Started',
      })
    },

    forceSyncProduct(item) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          html: 'You want to force-upload the product? <br> <br> Doing this will ignore the price sync setting',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })
        .then(result => {
          if (result.isConfirmed) this.setProductOnSync(item, true)
        })
    },

    uniqueStockQuantities(shared_sku_shopify_products) {
      const stockQuantities = new Set();
      shared_sku_shopify_products.forEach((s_p) => {
        stockQuantities.add(s_p.stock_quantity);
      });
      return [...stockQuantities].join(', ');
    },

    openVariationsPopup(data)
    {
      this.openVariationsModal = true;
      this.selectedShopifyProduct = data
    },

    linkedProducts(etsy_product) {
      const linked = [];
      if(etsy_product != null){
          let upload_result = JSON.parse(etsy_product.upload_result)
          if (upload_result) {
            if (!upload_result.has_variations) {
              // Linked product
              if (etsy_product.shopify_product) {
                linked.push(etsy_product.shopify_product);
              }
              // SKU linked
              etsy_product.shared_sku_shopify_products.forEach((item) => {
                if (item.shopify_id !== etsy_product.shopify_product?.shopify_id) {
                  linked.push(item);
                }
              });
            } else {
              // Product
              if (etsy_product.shopify_product) {
                linked.push(etsy_product.shopify_product);
              }
            }
          } else {
            // SKU
            linked.push(...etsy_product.shared_sku_shopify_products);
          }
      }

      return linked;
    },

    getLinkingStatus(product) {
        if (!product.listing_id) {
              "Not Uploaded";
        }
        if (product.shopify_product) {
          return "Product Linked";
        } else if (product.sku_linked) {
          return "SKU Linked";
        } else {
          return "Not Linked";
        }
      },

      getChipColor(item)
      {
        if (!item.listing_id) {
            return "red";
        }
        if (item.shopify_product) {
          return "green";
        } else if (item.sku_linked) {
          return "primary";
        } else {
          return "red";
        }
      },

    isProductLinked(item) {
      return item.variant_id === 0;
    },
    formatStock(stock) {
      return stock === 0 ? 'stock in variation' : stock;
    },

    showSKUStatus(product1)
    {
      let parent_data = product1.upload_result;
      let prepared_data = product1.data;

        let item = JSON.parse(parent_data);
        let prepared_item = JSON.parse(prepared_data ? prepared_data : "[]" );

        return `${item ? (item.has_variations ? "skus in variations" : item.skus[0]) : (prepared_item?.has_variations ? "sku in variations" : prepared_item?.skus)} <br/> ${item ? item.quantity : prepared_item?.quantity} in stock | ${item?.price.currency_code} 
                ${item ? (item.price.amount / item.price.divisor) : prepared_item?.price} <br/> Status: ${product1.etsy_state}`
    },
  },
}
</script>