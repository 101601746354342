var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"20px"}},[_c('v-dialog',{attrs:{"scrollable":"","max-width":"800px"},model:{value:(_vm.openVariationsModal),callback:function ($$v) {_vm.openVariationsModal=$$v},expression:"openVariationsModal"}},[_c('v-card',{staticStyle:{"padding":"20px","height":"100%"}},[_c('div',[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Shopify Product")]),_c('th',[_vm._v("SKU")]),_c('th',[_vm._v("Linking Type")]),_c('th',[_vm._v("Stock")])])]),_c('tbody',_vm._l((_vm.linkedProducts(_vm.selectedShopifyProduct)),function(item,index){return _c('tr',{key:index},[_c('td',{staticStyle:{"padding":"10px"}},[_c('a',{attrs:{"target":"_blank"},on:{"click":function($event){return _vm.shopifyProductDetail(item.id)}}},[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('td',{staticStyle:{"padding":"10px"}},[_vm._v(_vm._s(item.sku))]),_c('td',{staticStyle:{"padding":"10px"}},[(_vm.isProductLinked(item))?_c('span',[_vm._v("Product Linked")]):_c('span',[_vm._v("SKU Linked")])]),_c('td',{staticStyle:{"padding":"10px"}},[_vm._v(_vm._s(_vm.formatStock(item.stock_quantity)))])])}),0)])])])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.products.data,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticStyle:{"padding":"10px"}},[_c('v-col',{attrs:{"md":"2","cols":"6"}},[_c('strong',[_vm._v("Total: ")]),_vm._v(" "+_vm._s(_vm.products.total)+" ")]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Search Keyword","outlined":"","dense":"","placeholder":"Search Keyword","hide-details":""},on:{"change":function($event){return _vm.fetchEtsyProducts()}},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)],1)]},proxy:true},{key:"body",fn:function(ref){
var items = ref.items;
return [(!_vm.is_loading)?_c('tbody',[(!items.length)?_c('tr',[_c('td',{staticClass:"text-center",staticStyle:{"padding":"20px 0px"},attrs:{"colspan":"3"}},[_c('img',{attrs:{"src":require('@/assets/images/misc/no-data.png'),"alt":"","width":"250px"}}),_c('p',{staticStyle:{"font-size":"25px"}},[_vm._v("No Data Found!!")])])]):_vm._l((items),function(item){return _c('tr',{key:item.id},[_c('td',{staticStyle:{"padding":"10px","width":"45%"},attrs:{"set":(_vm.etsy_images = JSON.parse(item.fetched_imagesdata))}},[_c('div',{staticStyle:{"display":"flex"}},[_c('img',{staticClass:"mr-3",attrs:{"src":_vm.etsy_images && _vm.etsy_images[0] && _vm.etsy_images[0].url_fullxfull
                    ? _vm.etsy_images[0].url_fullxfull
                    : require('@/assets/images/misc/no-image.png'),"width":"100px","height":"100px","alt":""}}),_c('div',[_c('span',{staticClass:"primary--text",on:{"click":function($event){return _vm.etsyProductDetail(item.id)}}},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"mt-2"},[_c('strong',[_vm._v("Listing ID:")]),_vm._v(" "+_vm._s(item.listing_id))]),_c('p',{},[_c('strong',[_vm._v("SKU: "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.showSKUStatus(item))}})])])])])]),(item.shopify_product ||
              item.shared_sku_shopify_products.length > 0)?_c('td',{staticStyle:{"padding":"10px","width":"25%"},attrs:{"set":(_vm.sku_shared_shopify_products = item.shared_sku_shopify_products)}},[(item.shopify_product &&
                item.variations_count > 0 &&
                JSON.parse(item.upload_result).has_variations)?_c('div',[_c('div',{staticStyle:{"display":"flex"}},[_c('p',[_vm._v("stock in variations")])])]):(item.variations_count > 0 &&
                    item.shared_sku_shopify_products.length > 0 &&
                    JSON.parse(item.upload_result).has_variations)?_c('div',[_vm._v(" stock in variations ")]):(item.shared_sku_shopify_products.length > 0)?_c('div',[_c('img',{staticClass:"mr-3",attrs:{"src":require('@/assets/images/logos/shopify.png'),"width":"35px","height":"45px","alt":""},on:{"click":function($event){return _vm.openVariationsPopup(item)}}}),_vm._v(" "+_vm._s(_vm.uniqueStockQuantities(item.shared_sku_shopify_products))+" ")]):(_vm.product.shopify_product)?_c('div',[_c('img',{staticClass:"mr-3",attrs:{"src":require('@/assets/images/logos/shopify.png'),"width":"35px","height":"45px","alt":""},on:{"click":function($event){return _vm.openVariationsPopup(item)}}}),(_vm.product && _vm.product.shopify_product)?_c('p',[_vm._v("Stock Quantity: "+_vm._s(_vm.product.shopify_product.stock_quantity))]):_c('p',[_vm._v("Stock Quantity not available")])]):_vm._e()]):_c('td',[_c('p',[_vm._v("Not Available")])]),_c('td',{staticStyle:{"padding":"10px","width":"25%"}},[_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":_vm.getChipColor(item),"text-color":"white"}},[_vm._v(" "+_vm._s(_vm.getLinkingStatus(item))+" ")])],1)])})],2):_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center",staticStyle:{"padding":"80px 0px"},attrs:{"colspan":"6"}},[_c('img',{attrs:{"src":require('@/assets/images/misc/loading.gif'),"alt":"","width":"100px"}})])])])]}}])}),_c('v-pagination',{staticClass:"my-4",attrs:{"length":_vm.products.last_page,"total-visible":7},on:{"input":_vm.handlePageChange},model:{value:(_vm.products.current_page),callback:function ($$v) {_vm.$set(_vm.products, "current_page", $$v)},expression:"products.current_page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }